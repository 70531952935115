import React from "react";
import useSheet from "react-jss";

const desktopBreakPoint = "@media only screen and (min-width: 767px)";

const styled = useSheet({
	icon: {
		display: "inline-block",
		verticalAlign: "baseline",
		width: "1em",
		height: "1em",
		marginLeft: ".5em",
		marginRight: ".5em",
		[desktopBreakPoint]: {
			display: "block",
			width: "auto",
			height: "2em",
			margin: "0 auto 10px"
		}
	}
});

export const InvestmentIcon = styled(({ classes }) => (
	<svg
		className={classes.icon}
		viewBox="0 0 160 160"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<g
			id="investment_icon_160px"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g
				id="investment_icon_large-copy"
				transform="translate(1.000000, 1.000000)"
				stroke="#08B681"
				strokeWidth="2.5"
			>
				<g>
					<path
						d="M122,0 L155,0 C156.656854,-3.04359188e-16 158,1.34314575 158,3 L158,158 L158,158 L119,158 L119,3 C119,1.34314575 120.343146,3.04359188e-16 122,0 Z"
						id="Rectangle"
					/>
					<path
						d="M62,59 L96,59 C97.6568542,59 99,60.3431458 99,62 L99,158 L99,158 L59,158 L59,62 C59,60.3431458 60.3431458,59 62,59 Z"
						id="Rectangle-Copy-2"
					/>
					<path
						d="M3,107 L36,107 C37.6568542,107 39,108.343146 39,110 L39,158 L39,158 L0,158 L0,110 C-2.02906125e-16,108.343146 1.34314575,107 3,107 Z"
						id="Rectangle-Copy-3"
					/>
					<polyline
						id="Path-7-Copy"
						strokeLinecap="round"
						strokeLinejoin="round"
						points="0 74 85 0 59.5356115 0"
					/>
					<path d="" id="Path-13" />
					<line
						x1="85"
						y1="0"
						x2="85"
						y2="23"
						id="Path-14"
						strokeLinecap="round"
					/>
				</g>
			</g>
		</g>
	</svg>
));

export const PropertyIcon = styled(({ classes }) => (
	<svg
		className={classes.icon}
		viewBox="0 0 175 160"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<g
			id="property_icon_160px"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g
				id="property_icon_large-copy"
				transform="translate(1.000000, 1.000000)"
				stroke="#0F70FF"
				strokeWidth="2.5"
			>
				<line
					x1="0"
					y1="158"
					x2="171"
					y2="158"
					id="Path-6"
					strokeLinecap="round"
				/>
				<polyline
					id="Path-7"
					strokeLinecap="round"
					strokeLinejoin="round"
					points="-3.79696274e-13 79 85.9544742 0 171 79"
				/>
				<line x1="158.5" y1="158" x2="158.5" y2="67.2441406" id="Path-12" />
				<line x1="12.5" y1="158" x2="12.5" y2="67.2441406" id="Path-12-Copy" />
				<path
					d="M23,57.5683594 L23,3.59962904 C23,1.61160882 24.4774603,0 26.3,0 L41.7,0 C43.5225397,0 45,1.61160882 45,3.59962904 L45,37"
					id="Path"
				/>
				<path
					d="M60,158 L60,95.3559322 C60,93.502502 61.9955308,92 64.4571429,92 L107.542857,92 C110.004469,92 112,93.502502 112,95.3559322 L112,158 L112,158"
					id="Path"
				/>
			</g>
		</g>
	</svg>
));
