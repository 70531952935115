import React from "react";
import { graphql } from "gatsby";
import Home from "../home";

const HomePage = ({ data }) => (
	<Home openSales={data.civicSourceOpenSaleCount} />
);

export default HomePage;

export const pageQuery = graphql`
	query HomeQuery {
		civicSourceOpenSaleCount {
			adjudicationSale
			taxSale
		}
	}
`;
