import React from "react";
import { Button } from "@material-ui/core";
import useSheet from "react-jss";
import { robotoStack } from "./typography";

const ColorizedButton = ({ classes, color, ...props }) => (
	<Button
		variant="contained"
		{...props}
		data-color={color}
		className={`${classes.root} ${props.className || ""}`}
	/>
);

const styles = {
	root: {
		...robotoStack,
		fontWeight: "500",
		"&, &:hover, &:focus, &:active": {
			backgroundColor: props => props.color,
			color: "#fff",
			textDecoration: "none",
			boxShadow: "none"
		},
		textTransform: "capitalize",
		fontSize: "1rem"
	}
};

export default useSheet(styles)(ColorizedButton);
