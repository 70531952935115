import React from "react";
import { Link } from "gatsby";
import useSheet from "react-jss";
import ColorizedButton from "../../colorized-button";
import {
	COLOR_AUCTION_PROPERTY,
	COLOR_AUCTION_INVESTMENT,
	COLOR_AUCTION_COMBINED
} from "../../colors";
import { multiColumnOverMq as desktop } from ".";

const HeroButton = ({ isAdj, isTax, classes }) => {
	const destinationUrl =
		isAdj && isTax
			? "/auctions?saleTense=Now Open"
			: isAdj
			? "/auctions?saleType=Adjudication&saleTense=Now Open"
			: isTax
			? "/auctions?saleType=TaxSale&saleTense=Now Open"
			: "/auctions";

	const color =
		isTax && !isAdj
			? COLOR_AUCTION_INVESTMENT
			: isAdj && !isTax
			? COLOR_AUCTION_PROPERTY
			: COLOR_AUCTION_COMBINED;

	return (
		<div className={isAdj || isTax ? classes.saleOpen : classes.saleClosed}>
			<ColorizedButton
				className={classes.button}
				color={color}
				component={Link}
				size="large"
				to={destinationUrl}
			>
				Shop
			</ColorizedButton>
		</div>
	);
};

const styles = () => {
	return {
		root: {
			textAlign: "center",
			[desktop]: {
				textAlign: "left"
			}
		},
		saleClosed: {
			composes: "$root"
		},
		saleOpen: {
			composes: "$root"
		},
		button: {
			textTransform: "capitalize",
			"$saleClosed &": {
				paddingRight: "4.5em",
				paddingLeft: "4.5em"
			},
			"$saleOpen &": {
				width: "50%"
			}
		}
	};
};

export default useSheet(styles)(HeroButton);
