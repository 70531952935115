import React from "react";
import useSheet from "react-jss";
import HeroBlurb from "./hero-blurb";
import HeroBackground from "./hero-background";
import { Grid } from "react-bootstrap";
import { withProps, compose } from "recompose";

const ActiveSalesBanner = ({ classes, ...props }) => {
	return (
		<Grid className={classes.container}>
			<div className={classes.root}>
				<HeroBackground {...props} />
				<HeroBlurb {...props} />
			</div>
		</Grid>
	);
};

export const multiColumnOverMq = "@media screen and (min-width: 960px)";
export const multiColumnUnderMq = "@media screen and (max-width: 959px)";

const styles = theme => {
	const u = theme.spacing.unit;
	return {
		root: {
			display: "grid",
			gridRowGap: "0em",
			gridColumnGap: "2em",
			gridTemplateColumns: "1fr 1fr",
			gridTemplateRows: "1fr 1fr 1fr",
			width: "100%",
			height: "22vw",
			margin: [0, "auto"],
			[multiColumnUnderMq]: {
				minHeight: u * 30
			},
			[multiColumnOverMq]: {
				maxHeight: u * 40
			}
		},
		container: {
			position: "relative"
		}
	};
};

const withSaleFlags = withProps(({ sales }) => {
	const isAdj = !!sales.adjudicationSale;
	const isTax = !!sales.taxSale;
	return {
		isAdj,
		isTax
	};
});

const styled = useSheet(styles);

export default compose(
	withSaleFlags,
	styled
)(ActiveSalesBanner);
