import React from "react";

import { Grid } from "react-bootstrap";

import ActiveSalesBanner from "./active-sales-banner";
import CallToAction from "./call-to-action";
import Civic20 from "./civic-20";

import useSheet from "react-jss";
import { robotoStack } from "../typography";

const Root = ({ classes, openSales }) => (
	<div className={classes.container}>
		<Civic20 />
		<ActiveSalesBanner sales={openSales} />
		<Grid>
			<div
				style={{
					// even with distance between top and hero image
					paddingBottom: 12
				}}
			>
				<CallToAction />
			</div>
		</Grid>
		<br />
	</div>
);

const styles = {
	container: {
		...robotoStack,
		background: "#fff"
	}
};

export default useSheet(styles)(Root);
