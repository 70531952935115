import React from "react";
import noSalesArtDesktop from "./no-sales-desktop.svg";
import noSalesArtMobile from "./no-sales-mobile.svg";
import lienSalesArtDesktop from "./lien-sales-desktop.svg";
import lienSalesArtMobile from "./lien-sales-mobile.svg";
import deedSalesArtDesktop from "./deed-sales-desktop.svg";
import deedSalesArtMobile from "./deed-sales-mobile.svg";
import lienAndDeedSalesArtDesktop from "./lien-and-deed-sales-desktop.svg";
import lienAndDeedSalesArtMobile from "./lien-and-deed-sales-mobile.svg";
import useSheet from "react-jss";
import { multiColumnOverMq, multiColumnUnderMq } from ".";

const HeroBackground = ({ classes }) => {
	return <div className={classes.backgroundArt} />;
};

const styles = () => {
	return {
		backgroundArt: {
			zIndex: 0,
			backgroundImage: ({ isAdj, isTax }) =>
				`url(${
					isAdj && isTax
						? lienAndDeedSalesArtMobile
						: isAdj
						? deedSalesArtMobile
						: isTax
						? lienSalesArtMobile
						: noSalesArtMobile
				})`,
			backgroundSize: "contain",
			backgroundRepeat: "no-repeat",
			backgroundPosition: "50% 100%",

			gridColumn: "1 / -1", // Always fill width
			gridRow: "2 / -1", // Display below content

			// Mobile
			[multiColumnUnderMq]: {
				gridRow: "2 / -1" // Display below content
			},

			// Desktop
			[multiColumnOverMq]: {
				gridRow: "1 / -1", // Fill height
				backgroundImage: ({ isAdj, isTax }) =>
					`url(${
						isAdj && isTax
							? lienAndDeedSalesArtDesktop
							: isAdj
							? deedSalesArtDesktop
							: isTax
							? lienSalesArtDesktop
							: noSalesArtDesktop
					})`,
				backgroundPosition: ({ isAdj, isTax }) =>
					(isAdj && isTax) || (isAdj && !isTax) ? "100% 100%" : "50% 100%"
			}
		}
	};
};

export default useSheet(styles)(HeroBackground);
