import { robotoStack } from "../../typography";

const styles = {
	container: {
		...robotoStack,
		padding: [19, 0],
		backgroundColor: "#f8f8f8",
		textAlign: "center"
	},
	notice: {
		...robotoStack,
		marginTop: -20,
		width: "100%",
		fontSize: "1rem",
		textAlign: "center",
		fontWeight: 400,
		backgroundColor: "#6c7f92",
		lineHeight: "1.6rem",
		color: "#ffffff"
	},
	body: {
		...robotoStack,
		margin: [0, "auto"],
		width: "85%",
		fontSize: "1rem",
		textAlign: "center",
		fontWeight: 400,

		lineHeight: "1.6rem"
	},
	header: {
		...robotoStack,
		fontSize: "1rem",
		textAlign: "center",
		fontWeight: 600
	},
	copyright: {
		fontSize: ".66rem"
	}
};

export default styles;
