import React from "react";
import { Typography } from "@material-ui/core";
import useSheet from "react-jss";

const HeroHeadline = ({ isAdj, isTax, classes }) => {
	return (
		<Typography variant="h4" gutterBottom className={classes.headline}>
			{isAdj && isTax ? (
				<>
					Property&nbsp;auctions&nbsp;and&nbsp;
					<wbr />
					tax&nbsp;sales&nbsp;are&nbsp;now&nbsp;open.
				</>
			) : isAdj ? (
				<>
					Property&nbsp;auctions&nbsp;
					<wbr />
					are&nbsp;now&nbsp;open.
				</>
			) : isTax ? (
				<>
					Tax&nbsp;sales&nbsp;
					<wbr />
					are&nbsp;now&nbsp;open.
				</>
			) : null}
		</Typography>
	);
};

const styles = {
	headline: {
		fontWeight: 300,
		"@media screen and (min-width: 1200px)": {
			fontSize: "2.5rem"
		},
		margin: [0, 0, "10px", 0]
	}
};

export default useSheet(styles)(HeroHeadline);
