import React from "react";
import useSheet from "react-jss";
import { Grid } from "react-bootstrap";
import styles from "./civic-20.style";

const Civic20 = ({ classes }) => {
	return (
		<div className={classes.container}>
			<Grid>
				<p className={classes.body}>Group three or more properties and save.</p>
				<p className={classes.body}>
					<span>
						<a href={"https://www.civicsource.com/help/property-bundling"}>
							Build a bundle!
						</a>
					</span>
				</p>
			</Grid>
		</div>
	);
};

export default useSheet(styles)(Civic20);
