import React from "react";
import HeroHeadline from "./hero-headline";
import useSheet from "react-jss";
import HeroButton from "./hero-button";
import { multiColumnUnderMq, multiColumnOverMq } from ".";

const HeroBlurb = ({ classes, ...props }) => {
	return (
		<div className={classes.blurb}>
			<HeroHeadline {...props} />
			<HeroButton {...props} />
		</div>
	);
};

const styles = theme => {
	const u = theme.spacing.unit;
	return {
		blurb: {
			zIndex: 1,
			textAlign: "center",
			width: "100%",
			height: "100%",

			// Mobile
			gridColumn: "1 / -1", // Fill width
			gridRow: "1 / 2", // Position above background
			[multiColumnUnderMq]: {
				padding: [u, 0]
			},

			// Desktop
			[multiColumnOverMq]: {
				textAlign: "left",
				gridColumn: "1 / 2", // Left half
				gridRow: "2 / 3" // Middle cell
			}
		}
	};
};

export default useSheet(styles)(HeroBlurb);
