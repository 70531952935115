import React from "react";
import { Link as GatsbyLink } from "gatsby";

// Copied from https://www.gatsbyjs.org/docs/gatsby-link/#reminder-use-link-only-for-internal-links because apparently
// the devs decided to not actually include this logic in the real component. This is annoying as hell, and clearly the
// work of someone who's never had to interact across a domain boundary.
//
// Fuck you, Gatsby devs.

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const MaybeGatsbyLink = ({
	children,
	to,
	activeClassName,
	partiallyActive,
	...rest
}) => {
	// Tailor the following test to your environment.
	// This example assumes that any internal link (intended for Gatsby)
	// will start with exactly one slash, and that anything else is external.
	const internal = /^\/(?!\/)/.test(to);

	// Use Gatsby Link for internal links, and <a> for others
	if (internal) {
		return (
			<GatsbyLink
				to={to}
				activeClassName={activeClassName}
				partiallyActive={partiallyActive}
				{...rest}
			>
				{children}
			</GatsbyLink>
		);
	}
	return (
		<a href={to} {...rest}>
			{children}
		</a>
	);
};

export default MaybeGatsbyLink; // Maybe it's Maybelline
