import { robotoStack } from "../../typography";
import {
	COLOR_AUCTION_PROPERTY,
	COLOR_AUCTION_INVESTMENT,
	BODY_TEXT
} from "../../colors";
import { bootstrap } from "toetag";

const styles = {
	container: { ...robotoStack, padding: [48, 0] },
	learnRowContainer: {
		display: "grid",
		gridTemplateColumns: "1fr 1fr",
		gridGap: "2em"
	},
	learnColumnContainer: {
		borderRadius: "15px",
		fill: "#FFFFFF",
		border: "1px solid #E2E2E2",
		padding: "3em 0"
	},
	icon: {
		display: "block",
		background: {
			size: ["auto", 50],
			repeat: "no-repeat",
			position: ["50%", "50%"]
		},
		width: "100%",
		margin: [0, "auto", 8, "auto"],
		height: 150,
		[`@media only screen and (max-width: ${bootstrap.screenXsMax})`]: {
			display: "inline-block",
			verticalAlign: "bottom",
			width: "2em",
			height: "1em",
			background: {
				size: ["auto", "100%"],
				position: ["100%", "50%"],
				repeat: "no-repeat"
			}
		}
	},
	header: {
		textAlign: "center",
		fontWeight: 300,
		fontSize: "4rem"
	},
	headerCertificates: {
		color: COLOR_AUCTION_INVESTMENT,
		fill: COLOR_AUCTION_INVESTMENT
	},
	headerAdjudication: {
		color: COLOR_AUCTION_PROPERTY,
		fill: COLOR_AUCTION_PROPERTY
	},
	headerLiens: {
		color: COLOR_AUCTION_INVESTMENT,
		fill: COLOR_AUCTION_INVESTMENT
	},
	body: {
		...robotoStack,
		fontSize: "1.7rem",
		textAlign: "center",
		color: BODY_TEXT
	}
};

export default styles;
