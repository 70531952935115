import React from "react";
import useSheet from "react-jss";
import styles from "./call-to-action.style";
import Link from "../../maybe-gatsby-link";
import { COLOR_AUCTION_PROPERTY, COLOR_AUCTION_INVESTMENT } from "../../colors";
import ColorizedButton from "../../colorized-button";
import { InvestmentIcon, PropertyIcon } from "./icons";

export const SALE_TYPES = {
	ADJ: "Adjudication",
	LIEN: "Liens"
};

const C = props => {
	const { classes } = props;
	return <div className={classes.learnColumnContainer} {...props} />;
};

const CallToActionHeader = ({ children, classes, type }) => {
	const headerTypeClass = classes[`header${type}`];
	const headerClass = `${classes.header} ${headerTypeClass}`;

	const typeQuery = type === SALE_TYPES.ADJ ? "Adjudication" : "TaxSale";

	return (
		<Link
			to={`/auctions?saleType=${typeQuery}`}
			style={{ textDecoration: "none" }}
		>
			<h3 className={headerClass}>
				{type === SALE_TYPES.ADJ ? <PropertyIcon /> : <InvestmentIcon />}
				{children}
			</h3>
		</Link>
	);
};

const LearnButton = ({ type }) => {
	const href =
		type === SALE_TYPES.LIEN
			? "/support/investment"
			: "//civicsource.auctions.store/support";

	return (
		<div className="text-center">
			<ColorizedButton
				component={Link}
				to={href}
				color={
					type === SALE_TYPES.LIEN
						? COLOR_AUCTION_INVESTMENT
						: COLOR_AUCTION_PROPERTY
				}
				style={{
					paddingRight: "3.5em",
					paddingLeft: "3.5em",
					width: "50%",
					marginTop: "1em"
				}}
			>
				Learn
				<span className="sr-only">
					{" "}
					{type === SALE_TYPES.LIEN
						? "Investment Auctions"
						: "Property Auctions"}
				</span>
			</ColorizedButton>
		</div>
	);
};

const CallsToAction = ({ classes }) => {
	const certHeader = (
		<CallToActionHeader type={SALE_TYPES.LIEN} {...{ classes }}>
			<div>Investment</div>
		</CallToActionHeader>
	);
	const certBody = (
		<p className={classes.body}>Buy property-secured investments.</p>
	);
	const certLearnButton = <LearnButton type={SALE_TYPES.LIEN} />;

	const adjHeader = (
		<CallToActionHeader type={SALE_TYPES.ADJ} {...{ classes }}>
			<div>Property</div>
		</CallToActionHeader>
	);
	const adjBody = (
		<p className={classes.body}>
			Buy&nbsp;property&nbsp;for&nbsp;
			<wbr />
			$0&nbsp;plus&nbsp;costs.
		</p>
	);
	const adjLearnButton = <LearnButton type={SALE_TYPES.ADJ} />;

	return (
		<div className={classes.container}>
			<div className={"visible-xs"}>
				<div className={classes.learnColumnContainer}>
					{adjHeader}
					{adjBody}
					{adjLearnButton}
				</div>
				<br />
				<div className={classes.learnColumnContainer}>
					{certHeader}
					{certBody}
					{certLearnButton}
				</div>
			</div>
			<div className={["hidden-xs", classes.learnRowContainer].join(" ")}>
				<C classes={classes}>
					{adjHeader}
					{adjBody}
					{adjLearnButton}
				</C>
				<C classes={classes}>
					{certHeader}
					{certBody}
					{certLearnButton}
				</C>
			</div>
		</div>
	);
};

export default useSheet(styles)(CallsToAction);
